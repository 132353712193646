// colors

.bg-green {
  background-color: #48B3C3 !important;
  background: #48B3C3 !important;
}

.bg-gradient-theme {
  background: linear-gradient(to bottom, theme-color("primary"), theme-color("secondary"));
}
.bg-gradient-theme-top {
  background: linear-gradient(to top, theme-color("primary"), theme-color("secondary"));
}
.bg-gradient-theme-left {
  background: linear-gradient(to left, theme-color("primary"), theme-color("secondary"));
}
.bg-gradient-theme-right {
  background: linear-gradient(to right, theme-color("primary"), theme-color("secondary"));
}
