.nav-item {
  .nav-link {
    display: flex;
    align-items: center;

    &.active {
      color: #fff;
      background: rgba(255, 255, 255, .23);
      opacity: 1;
    }
  }
}

.sidebar .nav a {
  transition: all 150ms ease-in;
}

.card-header {
  text-transform: uppercase;
}

.badge:empty {
  display: inline-block;
}

.reg-form-checkbox {
  background: #eee;
  max-width: 300px;
  padding: 5px 30px;
  border-radius: 5px;
}

.ql-container {
  max-width: 100% !important;
}

.rating-star {
  color: #DCB976
}

button.btn-main {
  border: solid 4px #FFE9BF !important;
  border-radius: 6px;
  color: #3B3215;
  font-weight: 700;
  font-family: Rokkitt;
  text-transform: uppercase;
  padding: 10px 18px;
  background: #EBE4D4;
  box-shadow: inset 0 0 15px rgba(0, 0, 0, 1);
  transition: .5s;
}

button.btn-main:hover,
button.btn-main:active,
button.btn-main:not(:disabled):not(.disabled):active {
  border: solid 4px #FFE9BF !important;
  background: #9C824C;
  color: #3B3215;
  box-shadow: inset 0 0 15px rgba(0, 0, 0, 1);
}

button.btn-main:focus {
  box-shadow: inset 0 0 15px rgba(0, 0, 0, 1);
}

.avatar-popup {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.card-title {
  font-size: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Rokkitt;
}

.form-control.login-form-input {
  padding: 7px 20px;
  height: 48px;
  background: transparent;
  margin-bottom: 30px;
  color: #fff;
}

.login-form-label {
  margin-bottom: 0;
}

.login-form-container {
  max-width: 456px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
}

.login-form-header {
  font-size: 48px;
}

.login-form-nav-header {
  font-size: 36px;
}

.text-logo {
  font-size: 45px;
  font-weight: 600;
  text-transform: uppercase;
}

.modal.show {
  display: block;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}

.ql-editor {
  min-height: 200px;
  max-height: 600px;
  padding: 0 15px;
  overflow-y: scroll !important;
}

#page-table-books .sort-buttons,
#page-table-chapters .sort-buttons {
  cursor: pointer;
}

/* for quill wysiwyg */

.ql-size-large {
  //  font-size: 1.5em !important;
}

.ql-size-huge {
  // font-size: 2.5em !important;
}

.ql-direction-rtl {
  direction: rtl !important;
}

.ql-container {
  max-width: 670px;
}

.ql-toolbar {
  position: sticky;
  z-index: 2;
}

.quill-editor-container {
  position: relative;
  margin-bottom: 20px;
}

/* for react table stylings */

.books-table .rt-td,
.books-table .rt-th {
  flex: 100 0 !important;
  text-align: center;
  border-left: none !important;
  border-right: none !important;
  padding: 20px 0 !important;
}

.books-table .rt-th {
  font-weight: 700;
}

.books-table .rt-td:first-child,
.books-table .rt-th:first-child {
  max-width: 150px;
}

.books-table .rt-td:first-child {
  max-height: 200px;
}

.books-table .rt-td:nth-child(2),
.books-table .rt-th:nth-child(2) {
  max-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.books-table .rt-td:nth-child(3),
.books-table .rt-th:nth-child(3) {
  max-width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  white-space: break-spaces;
}

.books-table .rt-td:nth-child(4),
.books-table .rt-th:nth-child(4) {
  max-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.ReactTable .rt-td {
  white-space: inherit !important;
}

.books-table .books-tab.books-table .rt-tbody .books-table .rt-td:last-child {
  text-overflow: unset;
}

.books-table .rt-td:last-child button {
  min-width: 120px;
}

.books-table .rt-td:last-child {
  // display: flex;
  display: block;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.books-table .rt-thead,
.chapters-table .rt-thead {
  border-bottom: 2px solid #dee2e6;
  box-shadow: none !important;
  padding: 10px 0;
}



.books-table .rt-tr-group:hover,
.chapters-table .rt-tr-group:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.chapters-table .rt-td,
.chapters-table .rt-th {
  flex: 100 0 !important;
  text-align: center;
  border-left: none !important;
  border-right: none !important;
  padding: 20px 0 !important;
}

.chapters-table .rt-th {
  font-weight: 700;
}

.chapters-table .rt-thead {
  padding: 10px 0;
}

.chapters-table .rt-td:first-child,
.chapters-table .rt-th:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  max-width: 150px;
}


.users-table .rt-td:first-child,
.users-table .rt-th:first-child {
  max-width: 120px !important;
}

.users-table .rt-td:nth-child(2),
.users-table .rt-th:nth-child(2) {
  max-width: 440px !important;
}

.users-table .rt-td:nth-child(3),
.users-table .rt-th:nth-child(3) {
  max-width: 220px !important;
}

.users-table .rt-td:nth-child(4),
.users-table .rt-th:nth-child(4) {
  max-width: 150px !important;
}

.users-table .rt-td:nth-child(5),
.users-table .rt-th:nth-child(5) {
  max-width: 180px !important;
}

.chapters-table .rt-td:nth-child(2),
.chapters-table .rt-th:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  max-width: 150px;
  white-space: break-spaces;
  word-break: break-all;
}


.chapters-table .rt-td:nth-child(3),
.chapters-table .rt-th:nth-child(3) {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  max-width: 365px;
  white-space: break-spaces;
  word-break: break-all;
}

.chapters-table .rt-td:nth-child(4),
.chapters-table .rt-th:nth-child(4) {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  max-width: 400px;
  white-space: break-spaces;
  word-break: break-all;
}

.chapters-table .rt-td:nth-child(5),
.chapters-table .rt-th:nth-child(5) {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  max-width: 200px;
}

.ReactTable .rt-thead .rt-th.-sort-desc:last-child,
.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc:last-child,
.ReactTable .rt-thead .rt-th.-sort-asc:last-child,
.ReactTable .rt-thead .rt-td.-sort-asc:last-child,
.ReactTable .rt-thead .rt-th.-sort-asc {
  box-shadow: none !important;
}


// .chapters-table .rt-td:nth-child(3) {
//   justify-content: left;
// }


.chapters-table .rt-thead .rt-th.-sort-asc .rt-resizable-header-content:after,
.books-table .rt-thead .rt-th.-sort-asc .rt-resizable-header-content:after {
  content: "\f078";
  margin-top: 4px;
  left: 90%;
  font-size: 16px;
  font-family: "Font Awesome 5 Free";
  position: absolute;
  color: #000;
}

.chapters-table .rt-thead .rt-th.-sort-desc .rt-resizable-header-content:after,
.books-table .rt-thead .rt-th.-sort-desc .rt-resizable-header-content:after {
  content: "\f077";
  margin-top: 4px;
  left: 90%;
  font-size: 16px;
  font-family: "Font Awesome 5 Free";
  position: absolute;
  color: #000;
}

.chapters-table .rt-thead .rt-th.-sort-asc:nth-child(3) .rt-resizable-header-content:after,
.chapters-table .rt-thead .rt-th.-sort-desc:nth-child(3) .rt-resizable-header-content:after {
  left: 61%;
}

.books-table .rt-thead .rt-th.-sort-desc:nth-child(3) .rt-resizable-header-content:after,
.books-table .rt-thead .rt-th.-sort-asc:nth-child(3) .rt-resizable-header-content:after {
  left: 60%;
}

.chapters-table .rt-thead .rt-th.-sort-asc:nth-child(4) .rt-resizable-header-content:after,
.chapters-table .rt-thead .rt-th.-sort-desc:nth-child(4) .rt-resizable-header-content:after {
  left: 73%;
}

.chapters-table .rt-thead .rt-th.-sort-desc:last-child .rt-resizable-header-content:after,
.books-table .rt-thead .rt-th.-sort-desc:last-child .rt-resizable-header-content:after {
  content: '';
  font-size: 0px;
}

.chapters-table .rt-thead .rt-th.-sort-asc:last-child .rt-resizable-header-content:after,
.books-table .rt-thead .rt-th.-sort-asc:last-child .rt-resizable-header-content:after {
  content: '';
  font-size: 0px;
}

.rt-tr {
  width: 100% !important;
  background: white !important;
}

// .rt-td:first-child::before {
//   content: '\f077';
//   font-family: "Font Awesome 5 Free";
//    position: absolute;
//   color: #000;
//   font-size: 16px;
// }

// .rt-tbody .rt-td:first-child::before {
//   content: "...";
//   font-size: 25px;
//   display: inline-flex;
//   align-items: center;
//   justify-content: start;
// }

.rt-tbody .rt-tr {
  position: relative;
}


/* Users Table Added Stylings */


#add-user-modal {
  max-width: 600px !important;
}

#add-user-modal .modal-body {
  padding: 30px;
}

#add-user-modal .add-user-input {
  height: 48px;
  border: solid 1px #000;
  outline: none;
  box-shadow: none;
  width: 100%;
  border-radius: 0 !important;
}

#add-user-modal .subscription-container {
  margin-left: 21px;
}